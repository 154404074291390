<template>
  <v-container class="fullscreen">
    <v-col>
            <v-col :cols= breakpoints style="margin: 0 auto;">
            <h1 class="text-center mb-8">Passwort zurücksetzen</h1>
            <v-text-field 
            outlined
            type="password"
            label="Passwort"
            v-model="password"
            ></v-text-field>
            <v-btn 
            color="primary"
            x-large 
            class="col-12"
            :loading = loading
            @click="reset()"
            >Passwort zurücksetzen</v-btn>
            </v-col>
    </v-col>
    </v-container>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'
export default {
    data: () => {
        return {
            password: ""
        }
    },
    methods: {
        reset() {
          this.loading = true
          let id = this.$route.params.id
            axios
            .post('https://kjg-api.rezept-zettel.de/reset-password/' + id, {
              password: this.password,
            })
            .then(Response => {
              this.loading = false
              Vue.$toast.open({
                  message: `${Response.data}`,
                  type: 'success',
              });
            })
            .catch((err) => {
              console.log(err)
              this.loading = false
              Vue.$toast.open({
                  message: 'Versuche es später erneut',
                  type: 'error',
              });
            })
        }
    }
}
</script>

<style>
</style>